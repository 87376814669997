.control-panel {
    @apply w-[360px] top-[70px] left-[18px] p-[10px] z-[900] absolute;
    background: #FFFFFF66;
    backdrop-filter: blur(5px);
    border-radius: 12px;
    @apply flex flex-col;

    height: auto;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

.control-panel {    
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(138, 138, 138, 0.5) transparent; /* Hide scrollbar in Firefox */
}

.control-panel::-webkit-scrollbar {
    width: 4px;
    border-radius: 12px;
    background: rgba(138, 138, 138, 0.5) transparent;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.control-panel:hover::-webkit-scrollbar {
    opacity: 1;
}

.control-panel::-webkit-scrollbar-thumb {
    background: rgba(138, 138, 138, 0.5);
    border-radius: 6px;
}

.control-panel::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 6px;
}

.control-panel .search-box {
    position: relative;
    font-family: "Montserrat";
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    max-height: 50px; 
    background-color: white;
    border-radius: 8px;
    z-index: 10; /* Keep above layers and dropdowns */
    padding: 6.7px 12px;
    margin-bottom: 6px; /* Add spacing below the search box */
}

.control-panel .search-box input {
    width: calc(100% - 30px);
    border: none;
    outline: none;
    font-size: 14px; /* Increase text size */
    background: transparent; /* Transparent background */
}

.control-panel .clear-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 20px;
    color: #B5B5B5;
    cursor: pointer;
    padding: 0;
  }
  
  .control-panel .clear-button:hover {
    color: #888;
    opacity: 100%;
    border-radius: 8px;
  }

.control-panel .search-dropdown {
    position: absolute;
    margin-top: 8px;
    left: 0;
    width: 100%; /* Match search box width */
    background-color: white;
    border: 1px solid #B5B5B566;
    border-radius: 8px;
    max-height: 250px;
    overflow-y: auto;
    z-index: 10;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.control-panel .search-dropdown li {
    padding: 8px;
    cursor: pointer;
    font-size: 12px;
    margin: 8px;
    transition: background-color 0.2s ease;
    border-radius: 8px;
}

.control-panel .search-dropdown li:hover {
    background-color: #f0f0f0;
}

.control-panel .result-name {
    font-size: 14px; /* Larger main text size */
    font-weight: bold;
    color: #333; /* Darker text for contrast */
}

.control-panel .result-location {
    font-size: 12px; /* Slightly larger subtext size */
    color: #777; /* Lighter shade for secondary text */
}

.sources-panel {
    position: relative;
    @apply w-full rounded-[8px] bg-white;
    @apply flex flex-col p-2 items-start font-sans;
    overflow: hidden;
    margin-bottom: 6px;
    z-index: 9;
  }
  
  .layers-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .sources-title {
    font-size: 16px;
    font-weight: bold;
  }
  
  .selected-source-box {
    position: relative;
    max-height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background-color: #fff;
    border: 1px solid #B5B5B566;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;
    // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .selected-source-box:hover {
    background-color: #f9f9f9;
  }
  
  .arrow-icon {
    // margin: 0 12px;
    transition: transform 0.2s ease;
  }
  
  .open {
    transform: rotate(180deg);
  }
  
  .sources-dropdown {
    position: fixed;
    // top: calc(100% + 8px);
    margin-top: 8px;
    width: calc(100% - 86px);
    background-color: white;
    border: 1px solid #B5B5B566;
    border-radius: 8px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .sources-dropdown-item {
    padding: 8px;
    cursor: pointer;
    font-size: 12px;
    margin: 8px;
    transition: background-color 0.2s ease;
  }
  
  .sources-dropdown-item:hover {
    background-color: #f0f0f0;
    border-radius: 8px;
  }  
   
.layers-panel {
    @apply w-full rounded-[8px] bg-white; /* Rounded corners for layers panel */
    @apply flex flex-col p-2 items-start font-sans;
    padding-bottom: 5px;
    z-index: 2;
}

/* Hide scrollbar by default for layers panel */
.layers-panel {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(138, 138, 138, 0.5) transparent; /* Hide scrollbar in Firefox */
}

.layers-panel::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
    border-radius: 12px; /* Round corners for the scrollbar */
    background: rgba(138, 138, 138, 0.5) transparent; /* Color of the scrollbar track */
    opacity: 0; /* Hide scrollbar by default */
    transition: opacity 0.3s ease; /* Smooth transition for scrollbar */
}

.layers-panel:hover::-webkit-scrollbar {
    opacity: 1; /* Show scrollbar when hovering over the panel */
}

.layers-panel::-webkit-scrollbar-thumb {
    background: rgba(138, 138, 138, 0.5); /* Color of the scrollbar thumb */
    border-radius: 6px; /* Rounded corners for the scrollbar thumb */
}

.layers-panel::-webkit-scrollbar-track {
    background: transparent; /* Background of the scrollbar track */
    border-radius: 6px; /* Rounded corners for the scrollbar track */
}

.layers-header {
	@apply w-full flex flex-row justify-between items-center mb-1;
}

.layers-header.format-header {
    margin-top: 8px;
  }

.layers-title {
	@apply flex flex-row justify-between items-center w-[72px] font-semibold leading-4 text-black;
	font-size: 13px;
}

.layers-item {
	@apply mb-[8px] font-sans text-xs leading-normal text-black;
	font-style: normal;
	font-weight: 100;
} 

.format-dropdown {
    position: absolute; // Изменяем на absolute
    margin-top: 8px;
    width: 100%; // Ширина равна ширине родительского контейнера
    background-color: white;
    border: 1px solid #B5B5B566;
    border-radius: 8px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    left: 0; // Выравниваем по левому краю родительского контейнера
    right: 0; // Выравниваем по правому краю родительского контейнера
  }
  
  .format-dropdown-item {
    padding: 8px;
    cursor: pointer;
    font-size: 12px;
    margin: 8px;
    transition: background-color 0.2s ease;
  }
  
  .format-dropdown-item:hover {
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  
  .format-dropdown-wrapper {
    position: relative; // Добавляем relative для корректного позиционирования
    width: 100%;
    margin-bottom: 6px;
  }
  
  .selected-format-box {
    @extend .selected-source-box; // Используем стили от selected-source-box
  }

.email-panel {
    @apply mt-1;
    @apply w-full rounded-[8px] bg-white;
    @apply flex flex-col p-2 items-start font-sans;
    @apply mb-2;
    position: relative;
}

.email-wrapper {
    @apply relative;
    @apply w-full;
}

.email-content {
    @apply w-full h-[36px] border-[1px] mt-[8px] rounded-[8px] p-1;
    box-sizing: border-box;
    transition: border-color 1s ease;
    background: transparent;
}

.email-content::placeholder {
    font-size: 12px;
    line-height: 10px;
    display: flex;
    align-items: center;
    color: #B5B5B5;
}

.email-content:focus, .email-content:active {
    @apply border-[1px] rounded-[8px] border-[#1A1A1A];
    outline: none;
    transition: margin-bottom 0.2s ease-in-out;
}

.email-content.email-error {
    border-color: #8E1F0B;
	background-color: #FEE9E8;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 0;
}

.email-error-message {
    color: #8E1F0B;
    font-size: 12px;
    margin-top: 4px; /* Small space between the email input and error message */
    display: flex; /* Use flex to align icon and text */
    align-items: center; /* Center the icon and text vertically */
}

.info-icon {
    fill: #8E1F0B !important;
	margin-right: 2px;
}

.btn-submit {
	@apply w-full flex flex-row justify-around px-2 py-1 h-[38px] mt-1 bg-black text-white;
	@apply hover:bg-black hover:bg-opacity-90;
	color: white;
	@apply self-end; 
	@apply mt-auto;
}

.btn-submit:disabled {
	@apply bg-opacity-5;
	@apply hover:bg-black hover:bg-opacity-10;
}

.animated-spinner {
	animation: spin .5s linear infinite;
}

@keyframes spin {
	to {transform: rotate(360deg);}
  }

.tooltip-info {
    position: absolute;
    left: 120%;
    z-index: 15;
    background: white;
    transform: translateY(-50%);
    border-radius: 5px;
    width: 200px;
    padding: 8px;
    box-shadow: -5px 5px 11px 0px rgba(26, 26, 26, 0.2);
    text-align: left;
    font-size: 12px;
    max-height: 200px;
}

.tooltip-arrow {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid white;
}