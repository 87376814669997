.btn {
	@apply inline-flex bg-transparent hover:bg-greyBg text-black text-xs font-medium font-sans items-center;
	@apply py-1 px-2;
	@apply rounded-md;
}

.btn-blue {
	@apply bg-blue hover:bg-blue hover:opacity-80 text-white;
	@apply py-[8px] px-8;
	background: linear-gradient(60deg, #17A1FA 13.4%, #6B57F5 86.6%);
	border-radius: 6px;
}

.btn:disabled {
	@apply text-greyDisabled;
}

.btn-giveFeedback {
	@apply w-full flex flex-row justify-around px-2 py-1 h-[32px] bg-black text-white;
    @apply hover:bg-black hover:bg-opacity-90;
    color: white;
    @apply self-end mt-auto; 
}