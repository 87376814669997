.p-link {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
    margin-left: -10%;
    margin-right: -10%;
}

.donation-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.donation-icons svg {
    width: 70px;
    height: 70px;
    border-radius: 8px;
}

.p-0.m-0.mt-2.flex {
    justify-content: center;
}
