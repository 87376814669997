.MainPage {
    &_panel {
        z-index: 900;
        position: absolute;
        width: 360px;
        height: 408px;
        background: #FFFFFF66;
        backdrop-filter: blur(5px);
        border-radius: 12px;
        top: 70px;
        left: 18px;
        padding: 10px;
    }
}

/* Popup Base Styling */
.maplibregl-popup-content {
    background-color: transparent; /* Transparent background */
    backdrop-filter: blur(5px); /* Blurring effect */
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

/* Blue popup (within area limit) */
.panel-within-limit {
    background-color: #17A1FA; /* Blue background */
    opacity: 0.9;
    color: white;
    padding: 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 5px;
    text-align: center;
    min-width: 100px;
    max-width: 150px;
    max-height: fit-content;
}

/* Red popup (exceeds area limit) */
.panel-exceeds-limit {
    background-color: #D2042D; /* Red background */
    opacity: 0.7;
    color: white;
    padding: 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 5px;
    text-align: center;
    min-width: 100px;
    max-width: 200px;
    max-height: fit-content;
}

.svg-delete-icon {
    cursor: pointer;
    z-index: 10;
}

.maplibregl-popup-anchor-bottom .maplibregl-popup-tip {
    display: none; /* Hide the anchor */
}

.trash-button {
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-weight: bold;
    color: gray;
    cursor: pointer;
    padding: 1px;
    line-height: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.trash-button::before {
    content: '×'; /* Символ крестика */
}

.trash-button:hover {
    color: darkgray;
}

.draw-tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 4px 12px;
    border-radius: 8px;
    font-size: 12px;
    pointer-events: none;
    white-space: nowrap;
    z-index: 1000;
  }
  