.bg {
	position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #80928c4a;
    backdrop-filter: blur(5px);
}

.popup-wrapper {
    @apply absolute left-1/2 top-1/2;
    transform: translate(-50%, -50%);
}

.popup-status-card {
   position: absolute;
   width: 352px;
   height: 345px;
   background-color: #FFFFFF;
   display: flex;
   flex-direction: column;
   margin: 0 auto;
   border-radius: 12px;
   left: calc(50% - 352px/2);
   top: calc(40% - 360px/2);
   padding: 16px;
}
 
.popup-title {
	width: 100%;
	margin: 0;
	padding: 0;
	margin-top: 24px;
	font-family: Montserrat;
	font-weight: 600;
	text-align: center;
	font-size: 18px;
	line-height: 22px;
}

.popup-title-empty {
	margin: 0
}

.popup-text {
	width: 100%;
	margin: 0;
	margin-top: 8px;
	padding: 0;
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	text-align: center;
}

.popup-tail-content {
	font-family: 'Montserrat', sans-serif;
    width: 352px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 12px;
    padding-top: 20px;
    padding-bottom: 4px;
    margin: 0 auto;
    margin-top: 8px;
    z-index: 1060; /* Ensure it's above other content */
}

.popup-tail-content-text {
    @apply text-center font-semibold;
}

.popup-donation {
    position: relative;
	left: calc(50% - 352px/2);
	top: calc(40% - 300px/2  + 16.5px + 300px);
}