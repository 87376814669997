.expired-data-page {
    @apply w-full h-screen flex justify-center items-center; // Full screen height
    background-color: #f5f5f5; // Uniform background for the whole page

    .content-container {
        display: flex;
        flex-direction: column; 
        justify-content: center; // Centers the button vertically
        align-items: center; // Centers the button horizontally
        background-color: white;
        padding: 40px;
        border-radius: 8px;
        max-width: 600px;
        width: 100%;
        text-align: center; // Ensures the text and button are centered
    }

    p {
        font-family: 'Montserrat', sans-serif; // Apply Montserrat to the text
        font-size: 16px; 
        color: #4a4a4a;
        margin-bottom: 20px; // Ensure spacing between text and button
    }

    .expiredDataLogo {
        width: 10vw; // Set width
        height: 10vw; // Set height
        margin-bottom: 16px; // Add some space between logo and text
        fill: #303030; // Change color to red
        fill-opacity: 0.9;
    }
    
}

.animated-spinner {
    animation: spin .5s linear infinite;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

.btn-redirectToMainPage {
    font-family: 'Montserrat', sans-serif; // Make sure Montserrat font is loaded
    width: 30%; // Adjust button width as per the style
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 38px;
    margin-top: 20px;
    background-color: #303030;
    color: #FFFFFF;
    border: none;
    border-radius: 8px;
    font-size: 13px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #1a1a1a;
    }

    &:disabled {
        background-color: #f0f0f0;
        color: #B5B5B5;

        &:hover {
            background-color: #f0f0f0;
        }
    }
}
