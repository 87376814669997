.checkbox-wrapper {
	@apply flex flex-row items-center;
}

.checkbox {
	@apply appearance-none w-4 h-4 mr-[8px];
	@apply border-[1px] rounded-[4px];
    border-color: #B5B5B5;
	z-index: 2;
}

.check-icon {
	@apply absolute z-50 left-[-2px];
	@apply scale-0;
}

.checkbox:checked {
	@apply bg-black border-black;
}

.checkbox:checked ~ .check-icon {
	@apply scale-100;
}

.checkbox:disabled {
	@apply border-greyDisabled bg-greyDisabled;
}