.Navbar {
    position: fixed;
    display: flex;
    z-index: 500;
    margin-left: 18px;
    margin-right: 18px;
    margin-top: 12px;
    width: calc(100% - 36px);
    height: 46px;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;

    &_link {
        text-decoration: none;
        font-size: 12px;
        color: #303030;
        font-family: "Montserrat";
        margin-left: 10px;
        margin-right: 10px;
    }

    &_main {
        font-size: 18px;
        font-weight: 600;
        text-align: left;

        &__wrapper {
            width: 139px;
            text-align: center;
        }
    }

    &_other {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 7px;
        margin-bottom: 7px;

        &_link {
            color: red;
        }

        &__offset {
            margin-left: 20px;
        }
    }
}

.about-dialog {
    z-index: 1060; // Make sure it's above other elements
    width: 352px;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.popup-logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px; // Space between logo and title
}

.SpatialHubLogo {
    width: 50px; // Logo size
    height: auto; // Maintain aspect ratio
    fill: #303030; // Logo color
}

.popup-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 0;
}

.popup-text-1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #303030;
    line-height: 1.5;
    text-align: center;
    margin: 0;

    p {
        margin-bottom: 8px; // Spacing between paragraphs
        margin-top: 10px;
    }

    strong {
        font-family: 'Montserrat', sans-serif; // Ensure font family is set
        font-weight: 600; // Makes text bold
        color: inherit; // Inherit color from parent
    }
}

.popup-text-2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #303030;
    line-height: 1.5;
    text-align: center;
    margin: 0;
    border-radius: 10px;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    background-color: #ffffff66;

    p {
        margin-bottom: 8px; // Spacing between paragraphs
        margin-top: 10px;
    }

    strong {
        font-family: 'Montserrat', sans-serif; // Ensure font family is set
        font-weight: 600; // Makes text bold
        color: inherit; // Inherit color from parent
    }
}

.popup-donation-btn {
    position: fixed; // Fixed to viewport
    width: 352px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    margin: 0;
    border-radius: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); // Center the element
    padding: 16px;
    padding-bottom: 12px;
    z-index: 1060;
}

// Select area button
.Navbar_other {
    // background-color: #e7e6e666;
    height: 30px;
}
.Navbar_other .select-area-btn:hover {
    background-color:transparent;
    font-weight: bolder; /* Slightly darker gray */
}

.Navbar_other .about-us-btn:hover {
    background-color: transparent;
    font-weight: bolder;
}

/* Social Media Section */
.follow-us {
    text-align: center;
    margin-top: 12px;
}

.follow-us p {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #303030;
    margin-bottom: 8px;
}

.social-media-icons {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.social-media-icons svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 6px;
}