body {
    margin: 0;
    overflow: hidden;
}

.feedback-page {
    font-family: Montserrat;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    position: relative;
    overflow: hidden;
}

.feedback-modal {
    font-family: Montserrat;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    width: 500px;
    max-width: 100%;
    position: relative;

    h2 {
        margin-top: 0;
        font-size: 16px;
        color: #333;
    }

    p {
        color: #555;
        font-size: 13px;
        margin-bottom: 20px;
    }

    .feedback-empty,
    .too-many-symbols {
        color: red;
        margin-top: 10px;
        font-size: 13px;
        display: block;
    }

    .feedback-empty {
        margin-top: 10px;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }

    .counter {
        font-size: 12px;
        color: #555;

        &.error {
            color: red;
        }
    }

    textarea {
        font-family: Montserrat;
        width: 100%;
        height: 300px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #ccc;
        font-size: 13px;
        resize: none;
        box-sizing: border-box;
        overflow: auto;
        transition: border-color 0.3s;

        &.error {
            border: 1px solid red;
        }

        &:focus,
        &:active {
            border: 1px solid #1A1A1A;
            background-color: #f0f0f0;
            outline: none;
        }

        &::-webkit-scrollbar {
            width: 12px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ccc;
            border-radius: 8px;
            border: 0px solid transparent;
        }

        &::-webkit-scrollbar-track {
            background-color: #f0f0f0;
            border-radius: 8px;
        }
    }

    button {
        font-family: Montserrat;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        height: 38px;
        margin-top: 10px;
        background-color: #303030;
        color: #FFFFFF;
        border: none;
        border-radius: 8px;
        font-size: 13px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #1a1a1a;
        }

        &:disabled {
            background-color: #f0f0f0;
            color: #B5B5B5;

            &:hover {
                background-color: #f0f0f0;
            }
        }
    }

    .popup {
        display: inline-block;
        font-family: Montserrat;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 10px 20px;
        border-radius: 10px;
        text-align: center;
        max-width: 80%; 
        max-height: 50%; 
        overflow: auto;
        width: auto;
        height: auto;
        white-space: pre-wrap; 
        overflow-wrap: break-word; 
        z-index: 1000;
    }


    .popup.error {
        background-color: rgba(255, 0, 0, 0.85);
    }

    .popup.success {
        background-color: rgba(0, 0, 0, 0.8);
    }

}
