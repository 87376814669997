.feedback-button-wrapper {
    display: flex;
    justify-content: center;
    margin: 0 16px 16px 16px;

    a {
        display: block; /* Make NavLink take full width */
        width: 100%;
    }
}

.feedback-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #303030;
    line-height: 1.5;
    text-align: center;
    margin: 0;

    p {
        margin-bottom: 12px; /* Spacing between paragraphs */
        margin-top: 10px;
    }
}