/* Container for the dialog */
.dialog-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

/* Flex row for status icon */
.status-icon-container {
    display: flex;
    flex-direction: row;
    padding: 24px 0;
    justify-content: center;
}

/* Text styles */
.status-message {
    padding: 0;
    text-align: center; /* Center text */
    line-height: 22px; /* Adjust line height */
    font-family: Montserrat;
    font-size: 18px; /* Font size */
    font-weight: 600; /* Bold text */
    padding-bottom: 8px;
}

.status-details {
    padding-top: 8px; /* Space above details */
    text-align: center; /* Center text */
    font-family: Montserrat;
    font-size: 12px; /* Font size for details */
    font-weight: 100;
    line-height: 18px; /* Adjust line height */
}

.btn-close {
    @apply w-full flex flex-row justify-around px-2 py-1 h-[38px] bg-black text-white;
    @apply hover:bg-black hover:bg-opacity-90;
    color: white;
    @apply self-end mt-auto; 
}
