/* .map { */
  /* display: flex;
  text-align: center; */
/* } */

.leaflet-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  /* overflow: visible; */
}